// DataContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { API_URL } from '../components/Link_URL';


const SettingContext = createContext();

export const SettingProvider = ({ children }) => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/sitesetting`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result.data[0]);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <SettingContext.Provider value={{data, loading, error}}>
      {children}
    </SettingContext.Provider>
  );
};
export const useSettingData = () => useContext(SettingContext);

