import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { IMG_URL } from './Link_URL';

const TestimonialsComponents = ({data}) => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

     
  return (
    <>
    <div className="container-fluid bg-secondary p-0">
        <div className="row g-0">
            <div className="col-lg-6" style={{minHeight: "500px"}}>
                <div className="position-relative h-100">
                    <img className="position-absolute w-100 h-100" src="assets/img/testimonial.png" style={{objectFit: "cover"}}/>
                </div>
            </div>
            <div className="col-lg-6 py-6 px-5">
                <h1 className="display-5 mb-4">What Our Student say!!!</h1>
                <div className="slider-container">
      <Slider {...settings}>


        {
            data && data.length>0 &&
            data.map((d,idx)=>(
                <div className="testimonial-item" key={idx}>
                    {
                       
                        d.std_saying && 
                        <div className="fs-5 fw-normal mb-4"  ><i className="fa fa-quote-left text-primary me-3"></i>
                        <p style={{overflow:"hidden"}} dangerouslySetInnerHTML={{ __html:  d.std_saying }}></p>
                        </div>


                    }
                        <div className="d-flex align-items-center">
                            {
                                d.img &&
                            <img className="assets/img-fluid rounded-circle w-25" src={`${IMG_URL}${d.img}`} alt=""/>


                            }
                            <div className="ps-4">
                                {
                                   d.name &&
                                   <h3>{d.name}</h3>
                                }
                                {
                                    d.course &&
                                <span className="text-uppercase">{ d.course}</span>


                                }
                                
                            </div>
                        </div>
                    </div>

            ))
        }
    
      </Slider>
    </div>

            </div>


        </div>
    </div>
    </>
  )
}

export default TestimonialsComponents