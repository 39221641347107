import React, { useEffect, useState } from 'react'
import HeaderComponent from '../components/HeaderComponent'
import FooterComponenet from '../components/FooterComponenet'
import { Link } from 'react-router-dom'
import { API_URL, IMG_URL } from '../components/Link_URL'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const AboutUsPage = () => {
    const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    window.scrollTo(0, 0);
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_URL}/aboutus`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result.data[0]);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  return (
    <HelmetProvider HelmetProvider>
          <Helmet>
        <title>
          About Us- Study Express
        </title>
        <meta name="description" content="Study Express Group Pvt. Ltd. dynamic institution dedicated achieving excellence in the field of education. We strive to provide comprehensive educational services that cover a wide range of areas under one roof. Located in Kathmandu Metropolia-3, Nepal, Study Express Group is a legally registered professional overseas education service provider. We have obtained the necessary registrations from the Nepalese government, including the 'Ministry of Education', 'Office of the Company Register,' and 'Inland Revenue Department Since 2016 AD, we have been actively engaged in counseling and recruiting students for overseas education, operating from our main office in Kathmandu and through our branch offices and associate partners across Nepal," />
        <meta name="keywords" content="Study abroad, Study In Japan, Study In Australia, Study in America" />

      </Helmet>
    <HeaderComponent/>
    {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '100vh' }}
        >
          <div className="spinner-border text-primary" role="status" style={{ width: '6rem', height: '6rem' }}>
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
       {!loading && (
        <>
            <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">About Us</h1>
                <Link to="/">Home</Link>
                <i className="far fa-square text-primary px-2"></i>
                <Link to="/about-us">About</Link>
            </div>
        </div>
    </div>


    <div className="container-fluid bg-secondary p-0">
        {
            data &&
            <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                {
                    data.about_title &&
                <h1 className="display-5 mb-4">{data.about_title}</h1>

                }
                {
                    data.about_img &&
                    <img className='img-fluid' src={`${IMG_URL}${data.about_img}`} alt='Study Express'/>
                }

                {/* <h4 className="text-primary mb-4">Diam dolor diam ipsum sit. Clita erat ipsum et lorem stet no lorem sit clita duo justo magna dolore</h4>
                <p className="mb-4">Et stet ipsum nonumy rebum eos justo, accusam invidunt aliquyam stet magna at et sanctus, vero sea sit amet dolores, sit dolor duo invidunt dolor, kasd rebum consetetur diam invidunt erat stet. Accusam nonumy lorem kasd eirmod. Justo clita sadipscing ea invidunt rebum sadipscing consetetur. Amet diam amet amet sanctus sanctus invidunt erat ipsum eirmod.</p>
                <a href="" className="btn btn-primary py-md-3 px-md-5 rounded-pill">Get A Quote</a> */}
                   {
                data.about_des &&
                <p className="mb-4 fs-4 font-monospace text-wrap text-primary about_des" style={{overflow:"hidden"}} dangerouslySetInnerHTML={{ __html:  data.about_des}} />
                
             }
            </div>
            <div className="col-lg-6">
                <div className="h-100 d-flex flex-column justify-content-center bg-primary p-5">
                    <div className="d-flex text-white mb-5">
                       
                        {
                            data.mission &&
                            <div className="ps-4">
                            <h2> <u>Mission</u></h2>
                            <p className="mb-4 fs-4 text-white font-monospace text-wrap" >{data.mission}</p>
                        </div>
                        }
                 
                    </div>
                    <div className="d-flex text-white mb-5">
                    {
                            data.vision &&
                            <div className="ps-4">
                            <h2><u>Vision</u></h2>
                            <p className="mb-4 fs-4 text-white font-monospace text-wrap" >{data.vision}</p>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
        }
     
    </div>
        </>
       )
    }



    <FooterComponenet/>
    
    </HelmetProvider>
  )
}

export default AboutUsPage