import logo from './logo.svg';
import './App.css';
import HeaderComponent from './components/HeaderComponent';
import { MyRoutes } from './MyRoutes';
import { SettingProvider } from './context/SettingContext';

function App() {
  return (
    <div className="body-wrapper">
      <SettingProvider>
    <MyRoutes/>
    </SettingProvider>
    </div>
  );
}

export default App;
