import React, { useEffect, useState } from 'react';
import HeaderComponent from '../components/HeaderComponent';
import { API_URL, IMG_URL } from '../components/Link_URL';
import { Link } from 'react-router-dom';
import FooterComponenet from '../components/FooterComponenet';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const GalleryPage = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_URL}/gallery`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setData(result.data);
            } catch (error) {
                setError(error);
            } finally {
                setLoading(false); // Ensure loading state is set to false even if there's an error
            }
        };

        fetchData();
    }, []);

    return (
        <HelmetProvider>
           <Helmet>
        <title>
          Gallery- Study Express
        </title>
       
        <meta name="description" content="Study Express Group Pvt. Ltd. dynamic institution dedicated achieving excellence in the field of education. We strive to provide comprehensive educational services that cover a wide range of areas under one roof. Located in Kathmandu Metropolia-3, Nepal, Study Express Group is a legally registered professional overseas education service provider. We have obtained the necessary registrations from the Nepalese government, including the 'Ministry of Education', 'Office of the Company Register,' and 'Inland Revenue Department Since 2016 AD, we have been actively engaged in counseling and recruiting students for overseas education, operating from our main office in Kathmandu and through our branch offices and associate partners across Nepal," />
        <meta name="keywords" content="Study abroad, Study In Japan, Study In Australia, Study in America" />

      </Helmet>
            <HeaderComponent />
            {loading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                    <div className="spinner-border text-primary" role="status" style={{ width: '6rem', height: '6rem' }}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            ) : (
                <>
                    <div className="container-fluid bg-dark p-5">
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="display-4 text-white">Gallery</h1>
                                <Link to="/">Home</Link>
                                <i className="far fa-square text-primary px-2"></i>
                                <Link to="/gallery">Gallery</Link>
                            </div>
                        </div>
                    </div>
                    <div className='container p-3'>
                        <div className='row g-4'>
                            {data && data.map((d, idx) => (
                                <div className='col-md-4 col-lg-4' key={idx}>
                                    <div className='card'>
                                        <div className='card-body'>
                                            {d.img && <img className='img-fluid p-3 w-100' src={`${IMG_URL}${d.img}`} alt="gallery" />}
                                            {d.caption && <h6 className="name p-3 text-center text-primary">{d.caption}</h6>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            )}
            <FooterComponenet /> 
        </HelmetProvider>
    );
}

export default GalleryPage;
