import React, { useEffect, useState } from 'react'
import HeaderComponent from '../components/HeaderComponent'
import FooterComponenet from '../components/FooterComponenet'
import { Link } from 'react-router-dom'
import { API_URL, IMG_URL } from '../components/Link_URL'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const ServicesPage = () => {

    const [services, setServices] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_URL}/services`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setServices(result.data);
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false);
                console.log("Error: ", error)

            }
        };

        fetchData();
    }, []);


  return (
    < HelmetProvider>
       <Helmet>
        <title>
          Services- Study Express
        </title>
        <meta name="description" content="Study Express Group Pvt. Ltd. dynamic institution dedicated achieving excellence in the field of education. We strive to provide comprehensive educational services that cover a wide range of areas under one roof. Located in Kathmandu Metropolia-3, Nepal, Study Express Group is a legally registered professional overseas education service provider. We have obtained the necessary registrations from the Nepalese government, including the 'Ministry of Education', 'Office of the Company Register,' and 'Inland Revenue Department Since 2016 AD, we have been actively engaged in counseling and recruiting students for overseas education, operating from our main office in Kathmandu and through our branch offices and associate partners across Nepal," />
        <meta name="keywords" content="Study abroad, Study In Japan, Study In Australia, Study in America" />

      </Helmet>
    <HeaderComponent/>
    {loading && (

<div
className="d-flex justify-content-center align-items-center"
style={{ minHeight: '100vh' }}
>
<div className="spinner-border text-primary" role="status" style={{ width: '6rem', height: '6rem' }}>
  <span className="visually-hidden">Loading...</span>
</div>
</div>


    )
}
{!loading && (
    <>
        <div className="container-fluid bg-dark p-5">
        <div className="row">
            <div className="col-12 text-center">
                <h1 className="display-4 text-white">Services</h1>
                <Link to="/">Home</Link>
                <i className="far fa-square text-primary px-2"></i>
                <Link to="/services">Services</Link>
            </div>
        </div>
    </div>
    <div className="container-fluid py-6 px-5">
        <div className="text-center mx-auto mb-5" style={{maxWidth: "600px"}}>
            <h1 className="display-5 mb-0">What We Offer</h1>
            <hr className="w-25 mx-auto bg-primary"/>
        </div>
        <div className="row g-5">


            {
                services && services.length>0 &&
                services.map((item,idx)=>(
                    <div key={idx} className={`row align-items-center${idx % 2 === 0 ? '' : ' flex-row-reverse'}`}>
                    <div className="col-md-6">
                        {
                            item.service_img &&
                            <img src={`${IMG_URL}${item.service_img}`} className="img-fluid" alt="Services" />

                        }
                    </div>
                    <div className="col-md-6">
                      {
                        item.service_name &&
                        <h2 className='text-primary'>{item.service_name}</h2>
                       
                      }

{
                            item.service_des &&
                            <div className='p-4' >
                            <p className="mb-4 fs-4 font-monospace text-wrap" style={{ overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: item.service_des }} />
                            </div>

                        }
                    </div>
                  </div>

                ))

            }
        </div>
    </div>
</>
        )}
    <FooterComponenet/>
    </HelmetProvider>
  )
}

export default ServicesPage