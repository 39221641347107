import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { API_URL, IMG_URL } from '../components/Link_URL';
import HeaderComponent from '../components/HeaderComponent';
import FooterComponenet from '../components/FooterComponenet';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const AbroadStudyPage = () => {
    const { id } = useParams();

    const [abroadStudy, setAbroadStudy] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        window.scrollTo(0, 0);
        const fetchData = async () => {
            try {
                const response = await fetch(`${API_URL}/abroadstudy/${id}`);
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const result = await response.json();
                setAbroadStudy(result.data);
                setLoading(false);

            } catch (error) {
                setError(error);
                setLoading(false);
                console.log("Error: ", error)

            }
        };

        fetchData();
    }, [id]);


   

    return (
        <HelmetProvider>
           <Helmet>
            {
               abroadStudy && abroadStudy.abroad_studies_name &&
               <title>
       
               { abroadStudy.abroad_studies_name ?  abroadStudy.abroad_studies_name : "Study Abroad"} -Study Express
        
           </title>

            }
     
        <meta name="description" content="Study Express Group Pvt. Ltd. dynamic institution dedicated achieving excellence in the field of education. We strive to provide comprehensive educational services that cover a wide range of areas under one roof. Located in Kathmandu Metropolia-3, Nepal, Study Express Group is a legally registered professional overseas education service provider. We have obtained the necessary registrations from the Nepalese government, including the 'Ministry of Education', 'Office of the Company Register,' and 'Inland Revenue Department Since 2016 AD, we have been actively engaged in counseling and recruiting students for overseas education, operating from our main office in Kathmandu and through our branch offices and associate partners across Nepal," />
        <meta name="keywords" content="Study abroad, Study In Japan, Study In Australia, Study in America" />

      </Helmet>
            <HeaderComponent />
            {loading && (
                <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ minHeight: '100vh' }}
                >
                    <div className="spinner-border text-primary" role="status" style={{ width: '6rem', height: '6rem' }}>
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {!loading && (
                <>
                    <div className="container-fluid bg-dark p-5">
                        {
                            abroadStudy &&
                            <div className="row">

                                <div className="col-12 text-center">
                                    {
                                        abroadStudy.abroad_studies_name &&

                                        <h1 className="display-4 text-white">{abroadStudy.abroad_studies_name}</h1>

                                    }
                                    <Link to="/">Home</Link>
                                    <i className="far fa-square text-primary px-2"></i>
                                    {
                                        abroadStudy.abroad_studies_name &&
                                        <Link to="">{abroadStudy.abroad_studies_name}</Link>

                                    }
                                </div>
                            </div>
                        }

                    </div>
                    <div className="container-fluid px-5" >
                        <div  >
                            {
                                abroadStudy.abroad_studies_img &&
                                <img className="img-fluid w-50 mb-5 mx-auto d-block pt-3" src={`${IMG_URL}${abroadStudy.abroad_studies_img}`} alt="" />


                            }
                            {
                                abroadStudy.abroad_studies_des &&
                                <div className='p-4' >
                                <p className="mb-4 fs-4 font-monospace text-wrap" style={{ overflow: "hidden" }} dangerouslySetInnerHTML={{ __html: abroadStudy.abroad_studies_des }} />
                                </div>

                            }

                            {
                                abroadStudy.abroad_studies_admi &&
                                <div className='p-4'>
                                    <h3><u>Admission Requirement</u></h3>
                                    <p className="fs-4 font-monospace text-wrap" style={{ overflow: "hidden" }} dangerouslySetInnerHTML={{
                                        __html: abroadStudy.abroad_studies_admi
                                    }} />
                                </div>


                            }


                            {
                                abroadStudy.abroad_studies_docs &&
                                <div className='p-4'>
                                    <h3><u>Required Documents</u></h3>
                                    <p className="mb-4 fs-4 font-monospace text-wrap" style={{ overflow: "hidden" }} dangerouslySetInnerHTML={{
                                        __html: abroadStudy.abroad_studies_docs
                                    }} />
                                </div>


                            }

                            {
                                abroadStudy.abroad_studies_pros &&
                                <div className='p-4'>
                                    <h3><u>Processing</u></h3>
                                    <p className=" fs-4 font-monospace text-wrap mb-0" style={{ overflow: "hidden" }} dangerouslySetInnerHTML={{
                                        __html: abroadStudy.abroad_studies_pros
                                    }} />
                                </div>


                            }
                        </div>

                    </div>


                </>
            )}




            <FooterComponenet />

        </HelmetProvider>
    )
}

export default AbroadStudyPage