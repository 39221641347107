import React from 'react'
import { Link } from 'react-router-dom'

export const HomeAboutComponent = ({data}) => {
 const about = data[0]

  return (
    <>
    <div className="container-fluid bg-secondary p-0">

        <div className="row g-0">
            <div className="col-lg-6 py-6 px-5">
                {
                    about &&
                    <>
                        {
                            about.about_title &&
                <h1 className="display-5 mb-4 text-primary "> {about.about_title}</h1>

                            
                        }
                    </>
                }
             {
                about.about_des &&
                <p className="mb-4 fs-4 font-monospace text-wrap" style={{overflow:"hidden"}} dangerouslySetInnerHTML={{ __html:  about.about_des.substring(0,550)+"......" }} />
                
             }
              
                <Link to="/about-us" className="btn btn-primary py-md-3 px-md-5 rounded-pill">Show More</Link>
            </div>
            <div className="col-lg-6 ">
                <div className="h-100 d-flex flex-column justify-content-center bg-primary p-5">
                    {
                       about.mission &&
                       <p className="mb-4 fs-4 text-white font-monospace text-wrap" >{about.mission .substring(0,550)+"....."}</p>
 
                    }
                    <div className='col-offset-3'>
                    <Link to="/about-us" className="btn btn-dark py-md-3 px-md-5 rounded-pill">Show More</Link>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}
