import React from 'react'
import { IMG_URL } from './Link_URL';

export const WeDoComponent = ({ data }) => {
    const weDo = data[0];
    
    return (
        < >
            <div className="container-fluid pt-4 px-5 mb-5">
                <div className="text-center mx-auto mb-5" style={{ maxWidth: "600px" }}>
                    <h1 className="display-5 mb-0">What We Offer</h1>
                    <hr className="w-25 mx-auto bg-primary" />
                </div>
                <div className="row g-5">
                    {
                        weDo &&
                        <>
                            <div className="col-lg-4 col-md-6">
                                <div className="service-item bg-secondary text-center px-5 hoverup">
                                    <div className="d-flex align-items-center justify-content-center bg-primary text-white  mx-auto mb-4 con" style={{ width: "90px", height: "90px" }} >
                                        {/* <i className="fa fa-user-tie fa-2x"></i> */}
                                        <img src={`${IMG_URL}${weDo.img1}`} alt='DOCUMENTATION' style={{ height: "285px" }} />
                                    </div>
                                    {
                                        weDo.title1 &&
                                        <h3 style={{ marginTop: "5rem" }}>{weDo.title1}</h3>


                                    }

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item bg-secondary text-center px-5 hoverup">
                                    <div className="d-flex align-items-center justify-content-center bg-primary text-white  mx-auto mb-4 con" style={{ width: "90px", height: "90px" }}>
                                        {/* <i className="fa fa-user-tie fa-2x"></i> */}
                                        <img src={`${IMG_URL}${weDo.img2}`} alt='VISA PROCEDURE' style={{ height: "285px" }} />
                                    </div>
                                    {
                                        weDo.title2 &&
                                        <h3 style={{ marginTop: "5rem" }}>{weDo.title2}</h3>


                                    }

                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="service-item bg-secondary text-center  hoverup">
                                    <div className="d-flex align-items-center justify-content-center bg-primary text-white  mx-auto mb-4 con" style={{ width: "90px", height: "90px" }}>
                                        {/* <i className="fa fa-user-tie fa-2x"></i> */}
                                        <img src={`${IMG_URL}${weDo.img3}`} alt='COUNSELLING SERVICES' style={{ height: "285px"}} />
                                    </div>
                                    {
                                        weDo.title3 &&
                                        <h3 style={{ marginTop: "5rem" }}>{weDo.title3}</h3>


                                    }

                                </div>
                            </div>

                        </>

                    }



                

                </div>
            </div>
        </>
    )
}
