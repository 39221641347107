import React, { useEffect, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';
import HeaderComponent from '../components/HeaderComponent'
import FooterComponenet from '../components/FooterComponenet'
import SliderComponent from '../components/SliderComponent'
import { WeDoComponent } from '../components/WeDoComponent'
import { HomeAboutComponent } from '../components/HomeAboutComponent'
import { API_URL } from '../components/Link_URL'
import TestimonialsComponents from '../components/TestimonialsComponents'

const HomePage = () => {
  const [sliderData, setSliderData] = useState(null);
  const [aboutData, setAboutData] = useState(null);
  const [weDoData, setWeDoData] = useState(null);
  const [testimonials, setTestimonials] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const fetchData = async () => {
      window.scrollTo(0, 0);
      try {
        // Fetch data for all components
        const [sliderResponse, aboutResponse, weDoResponse, testimonialsResponse] = await Promise.all([
          fetch(`${API_URL}/slider`),
          fetch(`${API_URL}/aboutus`),
          fetch(`${API_URL}/why-us`),
          fetch(`${API_URL}/testimonial`)
        ]);

        // Check if all responses are ok
        if (!sliderResponse.ok || !aboutResponse.ok || !weDoResponse.ok || !testimonialsResponse.ok) {
          throw new Error('Failed to fetch data from one or more APIs');
        }

        // Parse response data
        const [sliderData, aboutData, weDoData, testimonials] = await Promise.all([
          sliderResponse.json(),
          aboutResponse.json(),
          weDoResponse.json(),
          testimonialsResponse.json()
        ]);

        // Set state with fetched data
        setSliderData(sliderData.data);
        setAboutData(aboutData.data);
        setWeDoData(weDoData.data);
        setTestimonials(testimonials.data)
        setLoading(false); // Set loading to false when all data is fetched successfully
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of error
      }
    };

    fetchData();
  }, []);


  return (
    <HelmetProvider>
      <Helmet>
        <title>
          Home- Study Express
        </title>
       
        <meta name="description" content="Study Express Group Pvt. Ltd. dynamic institution dedicated achieving excellence in the field of education. We strive to provide comprehensive educational services that cover a wide range of areas under one roof. Located in Kathmandu Metropolia-3, Nepal, Study Express Group is a legally registered professional overseas education service provider. We have obtained the necessary registrations from the Nepalese government, including the 'Ministry of Education', 'Office of the Company Register,' and 'Inland Revenue Department Since 2016 AD, we have been actively engaged in counseling and recruiting students for overseas education, operating from our main office in Kathmandu and through our branch offices and associate partners across Nepal," />
        <meta name="keywords" content="Study abroad, Study In Japan, Study In Australia, Study in America" />

      </Helmet>
      <HeaderComponent />
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '100vh' }}
        >
          <div className="spinner-border text-primary" role="status" style={{ width: '6rem', height: '6rem' }}>
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}
      {!loading && (
        <>

          <SliderComponent data={sliderData} />
          <HomeAboutComponent data={aboutData} />
          <WeDoComponent data={weDoData} />
          <TestimonialsComponents data={testimonials} />
        </>
      )}
      <FooterComponenet />
    </HelmetProvider>

  )
}

export default HomePage