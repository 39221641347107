import React from 'react'
import { useSettingData } from '../context/SettingContext';
import { IMG_URL } from './Link_URL';
import { Link } from 'react-router-dom';

const FooterComponenet = () => {
    const { data, loading, error } = useSettingData();

    return (
        <>


            <div className="container-fluid bg-dark text-secondary p-5">
                <div className="row g-5">
                    {
                        data &&
                        <div className="col-lg-3 col-md-6">
                            {
                                data.site_title &&
                                <h3 className="text-white mb-4">{data.site_title}</h3>

                            }
                            <div className="d-flex flex-column justify-content-start">
                                {
                                    data.site_logo &&
                                    <img className='w-50' src={`${IMG_URL}${data.site_logo} `} alt='study express logo' />

                                }

                            </div>
                        </div>
                    }

                    <div className="col-lg-3 col-md-6">
                        <h3 className="text-white mb-4">Popular Links</h3>
                        <div className="d-flex flex-column justify-content-start">
                            <Link className="text-secondary mb-2" to="/"><i className="bi bi-arrow-right text-primary me-2"></i>Home</Link>
                            <Link className="text-secondary mb-2" to="/about-us"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</Link>
                            <Link className="text-secondary mb-2" to="/services"><i className="bi bi-arrow-right text-primary me-2"></i>Our Services</Link>
                            <a className="text-secondary" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                        </div>
                    </div>
                    {
                        data &&
                        <div className="col-lg-3 col-md-6">
                            <h3 className="text-white mb-4">Get In Touch</h3>
                            {
                                data.site_address &&
                                <p className="mb-2"><i className="bi bi-geo-alt text-primary me-2"></i>{data.site_address}</p>

                            }
                            {
                                data.site_email &&
                                <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i>{data.site_email}</p>

                            }
                             {
                                data.alt_mail &&
                                <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i>{data.alt_mail}</p>

                            }
                            {
                                data.site_phone &&
                                <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>+977-01-{data.site_phone}</p>


                            }
                            {
                                data.site_mobile &&
                                <p className="mb-0"><i className="bi bi-phone text-primary me-2"></i>+977-{data.site_mobile}</p>


                            }
                            <hr/>


                            <p className=' text-primary'>Branch</p>

                            {
                                data.branch_address &&
                                <p className="mb-0"><i className="bi bi-geo-alt text-primary me-2"></i>{data.branch_address}</p>


                            }
                            {
                                data.branch_phone &&
                                <p className="mb-0"><i className="bi bi-phone text-primary me-2"></i>+977-{data.branch_phone}</p>


                            }
                        </div>

                    }
                    {
                        data &&
                        <div className="col-lg-3 col-md-6">
                            <h3 className="text-white mb-4">Follow Us</h3>
                            <div className="d-flex">
                                {
                                    data.site_tw_link &&
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href={data.site_tw_link} target='_blank'><i className="fab fa-twitter fw-normal"></i></a>


                                }
                                {
                                    data.site_fb_link &&
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href={data.site_fb_link} target='_blank'><i className="fab fa-facebook-f fw-normal"></i></a>
                                }

                                {
                                    data.site_lin_link &&
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href={data.site_lin_link} target='_blank'><i className="fab fa-linkedin-in fw-normal"></i></a>
                                }
                                {
                                    data.site_in_link &&
                                    <a className="btn btn-lg btn-primary btn-lg-square rounded-circle" href={data.site_in_link} target='_blank'><i className="fab fa-instagram fw-normal"></i></a>

                                }



                            </div>
                        </div>
                    }


                </div>
            </div>
            <div className="container-fluid bg-dark text-secondary text-center border-top py-4 px-5" style={{ borderColor: "rgba(256, 256, 256, .1) !important" }}>

                {
                    data && data.site_title &&
                    <p className="m-0">&copy; <a className="text-secondary border-bottom" href="#">{data.site_title ? data.site_title : ""}</a>. All Rights Reserved. Designed by <a className="text-secondary border-bottom" target='_blank' href="https://blackhawk.edu.np/">BlackHawk</a></p>
                }
            </div>

        </>
    )
}

export default FooterComponenet