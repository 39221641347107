import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import ServicesPage from './pages/ServicesPage';
import AbroadStudyPage from './pages/AbroadStudyPage';
import ContactPage from './pages/ContactPage';
import PageNotFoundPage from './pages/PageNotFoundPage';
import GalleryPage from './pages/GalleryPage';

export const MyRoutes = () => {
  return (
   <>
    <Router>
    <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/abroad-study/:id" element={<AbroadStudyPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/gallery" element={<GalleryPage />} />

        <Route path="*" element={<PageNotFoundPage />} />



    </Routes>
    </Router>
   </>
  )
}
