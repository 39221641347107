import React from 'react'
import { Link } from 'react-router-dom'
import HeaderComponent from '../components/HeaderComponent'
import FooterComponenet from '../components/FooterComponenet'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const PageNotFoundPage = () => {
  return (
    <HelmetProvider>
       <Helmet>
        <title>
          About Us- Study Express
        </title>
        <meta name="description" content="Study Express Group Pvt. Ltd. dynamic institution dedicated achieving excellence in the field of education. We strive to provide comprehensive educational services that cover a wide range of areas under one roof. Located in Kathmandu Metropolia-3, Nepal, Study Express Group is a legally registered professional overseas education service provider. We have obtained the necessary registrations from the Nepalese government, including the 'Ministry of Education', 'Office of the Company Register,' and 'Inland Revenue Department Since 2016 AD, we have been actively engaged in counseling and recruiting students for overseas education, operating from our main office in Kathmandu and through our branch offices and associate partners across Nepal," />
        <meta name="keywords" content="Study abroad, Study In Japan, Study In Australia, Study in America" />

      </Helmet>
<HeaderComponent/>
<div className="bg-primary text-light py-5">
        <div className='container'>
            <div className='row'>
                <div className='col-md-8 mx-auto text-center'>
                <h1 className="display-1">404</h1>
            <p className="lead">Oops! Page not found.</p>
            <p>The page you are looking for might have been removed or doesn't exist.</p>
            <Link to="/" className="btn btn-outline-dark me-2">Go Back to Home</Link>
            <Link className='btn btn-outline-dark' to="/contact-us">Contact Us</Link>
                </div>
            </div>
        </div>

        </div>
        <FooterComponenet/>
    </HelmetProvider>

  )
}

export default PageNotFoundPage