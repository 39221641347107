import React from 'react'

const SliderComponent = ({ data }) => {
   
    const { imgurl } = data

    return (
        < >
            <div className="container-fluid p-0">

                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">

                    <div className="carousel-inner">
                        {data &&
                            imgurl &&
                            data.sliders &&
                            data.sliders.map((item, idx) => (
                                item && item.isActive === 1 && (
                                    <div
                                        className={`carousel-item ${idx === 0 ? 'active' : ''}`}
                                        key={idx}
                                    >
                                        <img className="w-100" src={`${imgurl}/${item.slider_img}`} alt="Image" />
                                    </div>
                                )
                            ))}
                    </div>

                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </>
    )
}

export default SliderComponent