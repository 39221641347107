import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { settingData, useSettingData } from '../context/SettingContext';
import { API_URL, IMG_URL } from './Link_URL';

const HeaderComponent = () => {
    const { data, loading, error } = useSettingData();

    const [abroadStudy, setAbroadStudy]=useState(null);
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await fetch(`${API_URL}/abroadstudy`);
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const result = await response.json();
            setAbroadStudy(result.data);
     
          } catch (error) {
           console.log("Error: ",error)
         
          }
        };
    
        fetchData();
      }, []);

      

    return (
        <>

            <div className="container-fluid bg-secondary ps-5 pe-0 d-none d-lg-block">
                <div className="row gx-0">
                    <div className="col-md-4 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center">
                            {
                                data && data.site_fb_link &&
                                <div className="me-3 pe-3 border-end py-2">
                                    <a target='_blank' href={data.site_fb_link} className="m-0"><i className="bi bi-facebook"></i></a>
                                </div>

                            }
                            {
                                data && data.site_in_link &&
                                <div className="me-3 pe-3 border-end py-2">
                                    <a target='_blank' href={data.site_in_link} className="m-0"><i className="bi bi-instagram"></i></a>
                                </div>

                            }
                            {
                                data && data.site_tw_link &&
                                <div className="me-3 pe-3 border-end py-2">
                                    <a target='_blank' href={data.site_tw_link} className="m-0"><i className="bi bi-twitter"></i></a>
                                </div>

                            }

                            {
                                data && data.site_lin_link &&
                                <div className="me-3 pe-3 border-end py-2">
                                    <a target='_blank' href={data.site_lin_link} className="m-0"><i className="bi bi-linkedin"></i></a>
                                </div>

                            }



                            {
                                data && data.site_tik_link &&
                                <div className="me-3 pe-3 border-end py-2">
                                    <a target='_blank' href={data.site_tik_link} className="m-0">Tiktok</a>
                                </div>

                            }

                            {


                                data && data.site_you_link &&
                                <div className="me-3 pe-3 border-end py-2">
                                    <a target='_blank' href={data.site_you_link} className="m-0"><i className="bi bi-youtube"></i></a>
                                </div>

                            }


                        </div>
                    </div>
                    <div className="col-md-8 text-center text-lg-end">
                        <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">

                            {

                                
                                data && data.site_email &&
                                <div className="me-3 pe-3 border-end py-2">
                                    <a href={`mailto: ${data.site_email}`} className="text-white m-0"><i className="fa fa-envelope-open me-2"></i>{data.site_email}</a>
                          
                                </div>

                            }


                            {


                                data && data.site_mobile
                                &&
                                <div className="me-3 pe-3 border-end py-2">
                                    <a href={`tel: ${data.site_mobile}`} className="text-white m-0"><i className="fa fa-phone-alt me-2"></i>+977-{data.site_mobile}</a>
                                </div>

                            }

                            {


                                data && data.site_phone &&
                                <div className="me-3 pe-3  py-2">
                                    <a href={`tel: ${data.site_phone}`} className="text-white m-0"><i className="fa fa-phone-alt me-2"></i>+977-01-{data.site_phone}</a>
                                </div>

                            }


                        </div>
                    </div>
                </div>
            </div>

            <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
                {
                    data && data.site_logo &&
                    <Link className="navbar-brand p-0" to="/">
                        <img src={`${IMG_URL}${data.site_logo}`} className='img-fluid' style={{ maxWidth: "250px", maxHeight: "100px" }} alt="study-express" />
                    </Link>

                }

                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-0 me-n3">
                        <NavLink to="/" className={({ isActive }) => (isActive ? "active nav-item nav-link" : "nav-item nav-link")} >Home</NavLink>
                        <NavLink to="/about-us" className={({ isActive }) => (isActive ? "active nav-item nav-link" : "nav-item nav-link")} >About</NavLink>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Abroad Study</a>
                            <div className="dropdown-menu m-0">
                                {
                                    abroadStudy && abroadStudy.length>0 &&
                                    abroadStudy.map((ab,idx)=>(
                                <NavLink key={idx} to={`/abroad-study/${ab.id}`} className="dropdown-item">{ab.abroad_studies_name}</NavLink>

                                    ))
                                }

                            </div>
                        </div>
                        <NavLink to="/services" className={({ isActive }) => (isActive ? "active nav-item nav-link" : "nav-item nav-link")} >Service</NavLink>
                        <NavLink to="/gallery" className={({ isActive }) => (isActive ? "active nav-item nav-link" : "nav-item nav-link")} >Gallery</NavLink>

                        <NavLink to="/contact-us" className={({ isActive }) => (isActive ? "active nav-item nav-link" : "nav-item nav-link")} >Contact</NavLink>
                      
                    </div>
                    

                </div>
            </nav>

        </>
    )
}

export default HeaderComponent