import React, { useEffect, useState } from 'react'
import HeaderComponent from '../components/HeaderComponent'
import FooterComponenet from '../components/FooterComponenet'
import { Link } from 'react-router-dom'
import { useSettingData } from '../context/SettingContext'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { API_URL } from '../components/Link_URL'

const ContactPage = () => {
  const { data, loading, error } = useSettingData();

  const [fullName, setFullName]= useState("");
  const [email, setEmail]= useState("");
  const [subject , setSubject]=useState("");
  const [message, setMessage]=useState("");
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [senderror, setSendError] = useState(null);


  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])


  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    const data = {
      fullName,
      email,
      subject,
      message
    }
    try {
      // Send form data to API endpoint
      const response = await fetch(`${API_URL}/contactus`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*'
        },
        body: JSON.stringify({
          fullName,
          email,
          subject,
          message,
        }),
      });
console.log("aaa",response)
      if (!response.ok) {
        throw new Error('Failed to submit form');
      }

      // Reset form fields and show success message
      setFullName("");
      setEmail("");
      setSubject("");
      setMessage("");
      setSubmitted(true);
    } catch (error) {
      setSendError(error.message);
    } finally {
      setSubmitting(false);
    }
  }
  return (
    <HelmetProvider>
       <Helmet>
        <title>
          Contact Us- Study Express
        </title>
        <meta name="description" content="Study Express Group Pvt. Ltd. dynamic institution dedicated achieving excellence in the field of education. We strive to provide comprehensive educational services that cover a wide range of areas under one roof. Located in Kathmandu Metropolia-3, Nepal, Study Express Group is a legally registered professional overseas education service provider. We have obtained the necessary registrations from the Nepalese government, including the 'Ministry of Education', 'Office of the Company Register,' and 'Inland Revenue Department Since 2016 AD, we have been actively engaged in counseling and recruiting students for overseas education, operating from our main office in Kathmandu and through our branch offices and associate partners across Nepal," />
        <meta name="keywords" content="Study abroad, Study In Japan, Study In Australia, Study in America" />

      </Helmet>
      <HeaderComponent />
      {loading && (
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ minHeight: '100vh' }}
        >
          <div className="spinner-border text-primary" role="status" style={{ width: '6rem', height: '6rem' }}>
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {!loading && (

        <>

          <div className="container-fluid bg-dark p-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="display-4 text-white">Contact Us</h1>
                <Link to="/">Home</Link>
                <i className="far fa-square text-primary px-2"></i>
                <Link to="/contact-us">Contact</Link>
              </div>
            </div>
          </div>


          <div className="container-fluid bg-secondary px-0">
            <div className="row g-0">
              <div className="col-lg-6 py-6 px-5">
                {
                  data &&
                  <>
                    <div>
                      {
                        data.site_title &&
                        <h3 className="mb-3 text-primary"><i className="bi bi-house text-primary me-2"></i>{data.site_title}</h3>

                      }
                          {
                                data.site_address &&
                                <h3 className="mb-2 text-primary"><i className="bi bi-geo-alt text-primary me-2"></i>{data.site_address}</h3>

                            }

                      {
                        data.site_email &&
                        <h3 className="mb-3 text-primary"><i className="bi bi-envelope-open text-primary me-2"></i>{data.site_email}</h3>

                      }
                       {
                        data.alt_mail &&
                        <h3 className="mb-3 text-primary"><i className="bi bi-envelope-open text-primary me-2"></i>{data.alt_mail}</h3>

                      }

                      {
                        data.site_phone &&
                        <h3 className="mb-3 text-primary"><i className="bi bi-telephone text-primary me-2"></i>+977-01-{data.site_phone}</h3>


                      }
                      {
                        data.site_mobile &&
                        <h3 className="mb-3 text-primary"><i className="bi bi-phone text-primary me-2"></i>+977-{data.site_mobile}</h3>


                      }
                      <hr/>
                      <h3>Branch</h3>
                      {
                        data.branch_address &&
                        <h3 className="mb-3 text-primary"><i className="bi bi-geo-alt text-primary me-2"></i>{data.branch_address}</h3>


                      }
                       {
                        data.branch_phone &&
                        <h3 className="mb-3 text-primary"><i className="bi bi-phone text-primary me-2"></i>{data.branch_phone}</h3>


                      }

                    </div>
                  </>

                }

                <hr />

                <h3 className=" mb-4">Contact For Any Queries</h3>
                {/* <form>
                  <div className="row g-3">
                    <div className="col-6">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="form-floating-1" placeholder="John Doe"  />
                        <label htmlFor="form-floating-1">Full Name</label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-floating">
                        <input type="email" className="form-control" id="form-floating-2" placeholder="name@example.com" />
                        <label htmlFor="form-floating-2">Email address</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="form-floating-3" placeholder="Subject" />
                        <label htmlFor="form-floating-3">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea className="form-control" placeholder="Message" id="form-floating-4" style={{ height: "150px" }}></textarea>
                        <label htmlFor="form-floating-4">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button className="btn btn-primary w-100 py-3" type="submit">Submit</button>
                    </div>
                  </div>
                </form> */}
                 <form onSubmit={handleSubmit}>
      <div className="row g-3">
        <div className="col-6">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="John Doe"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <label htmlFor="form-floating-1">Full Name</label>
          </div>
        </div>
        <div className="col-6">
          <div className="form-floating">
            <input
              type="email"
              className="form-control"
              placeholder="name@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <label htmlFor="form-floating-2">Email address</label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating">
            <input
              type="text"
              className="form-control"
              placeholder="Subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
            <label htmlFor="form-floating-3">Subject</label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating">
            <textarea
              className="form-control"
              placeholder="Message"
              style={{ height: "150px" }}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            ></textarea>
            <label htmlFor="form-floating-4">Message</label>
          </div>
        </div>
        <div className="col-12">
          <button
            className="btn btn-primary w-100 py-3"
            type="submit"
            disabled={submitting}
          >
            {submitting ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
      {submitted && <p className="text-success">Form submitted successfully!</p>}
      {error && <p className="text-danger">Error: {error}</p>}
    </form>
              </div>
              <div className="col-lg-6" style={{ minHeight: "400px" }}>
                {
                  data && data.site_map &&
                  <div className="position-relative h-100">
                    <iframe className="position-relative w-100 h-100"
                      src={data.site_map}
                      frameBorder="0" style={{ border: "0" }} allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                  </div>

                }


              </div>
            </div>
          </div>
        </>
      )
      }

      <FooterComponenet />

    </HelmetProvider>
  )
}

export default ContactPage